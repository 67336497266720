@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;


.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); 
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999; 
  max-height: 100%;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: transparent transparent; 
  -ms-overflow-style: none;
}
.gray-image{
  .gray-image {
    filter: grayscale(100%);
  }
}
.relimg{
  max-width: 100%;
  height: 113px;
}
@media (max-width: 768px) {
  .relimg {
    height: auto; /* Adjust the height for mobile view */
  }
}
.product-div {
  position: fixed;
  width: 100%;
  height: auto;
  z-index: 98;
  padding: 40px 50px;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: top 0.3s ease;
}

@media (min-width: 1100px) {
  .product-div {
      top: 119px;
  }
}

@media (max-width: 1099px) {
  .product-div {
      top: 119px;
  }
}

.slick-slide > div{
    margin: 0 10px;
  }

  input[password]::-ms-reveal {display : none ;}


  input[type=password]::-ms-reveal,
input[type=password]::-ms-clear
{
    display: none;
}

.swiper {
  width: 100%;
  height: 100%;
  
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
@media (max-width: 768px) { /* Adjust the max-width value based on your requirements */
  .swiper-slide {
    width: 99.333px;
    margin-right: 6px;
  }
}
.mySwiper{
  margin-left: 150px;
  

}
.mySwiper2{
  margin-left: 150px;
  width: 89%;
  

}

.card_image{
  box-shadow: 0px 2px 8px 0px rgba(0, 166, 219, 0.10), 2px 0px 4px 0px rgba(0, 166, 219, 0.10);
}

.person_name{
  color: #6D6E70;
  font-size: 13px;
  padding-top: 10px;
}

.partner_image {
  filter: grayscale(100%);

}
.opacity {
  opacity: 1; 
}
.faq-item {
  /* border-bottom: 1px solid #DDDDDE; */
  transition: max-height 0.3s ease-in-out, padding 0.3s ease-in-out;
  overflow: hidden;
  max-height: 3em; 
}

.faq-item.expanded {
  max-height: 1000px; 
  padding-bottom: 1em; 
}

.drop_shadow_2{
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}


.scroll::-webkit-scrollbar{
  display: none;
}

.wish_wrapper {
  transition: height 0.3s ease-in-out; 
}

.expanded {
  height: auto; 
}


.previous1{
  transform: rotate(180deg);
}

/* .wrapper_pro {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.detail {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  height: 300px; 
} */

.fixed-checkbox {
  position: absolute;
   /* Adjust as needed */
  right: 660px; /* Adjust as needed */
}




.myaccount_detail_list::-webkit-scrollbar{
  display: none;
}
.myaccount_detail_listt::-webkit-scrollbar{
  display: none;
}

.loader {
  width: 25px;
  height: 25px;
  border: 5px solid #FFF;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  }

  @keyframes rotation {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
  } 

  .loader2 {
    width: 25px;
    height: 25px;
    border: 5px solid #00A6DB;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    }
/*   
    @keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
    }  */


    #special_input{
      accent-color: blue;
    }
    #special_input2{
      accent-color: black;
    }


    
  
@media (min-width:1440px) {
  .container_maxWidth_1440{
    width: 100%;
    padding: 0 calc(50vw - 720px);
  }}


@media (max-width:425px) {
  .banner_image img{
    width: 50vh;
    margin-left: 10px;
  }  
  .banner_image{
    width: 30vh;
    margin-left: 1px;
  }
}

@media (max-width:365) {
  .below_s8{
    margin-top: 600px !important;
  }
  
}

.chakra-slide::-webkit-scrollbar {
  display: none;
}

.custom-loader2 {
  width: 20px;
  height: 20px;
  display: grid;
  animation: s4 4s infinite;
 
}
.custom-loader2::before,
.custom-loader2::after {    
  content:"";
  grid-area: 1/1;
  border:8px solid;
  border-radius: 50%;
  border-color:#FFFFFF #FFFFFF #0000 #0000;
  mix-blend-mode: darken;
  animation: s4 1s infinite linear;
}
.custom-loader2::after {
  border-color:#0000 #0000 #E4E4ED #E4E4ED;
  animation-direction: reverse;
}

@keyframes s4{ 
  100%{transform: rotate(1turn)}
}