.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.accordian2{
  border: none !important;
}
.accordian3{
  border: none !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.custom-loader {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background:#00A6DB;
  color: #00A6DB;
  clip-path: inset(-52px);
  animation: d8 2s infinite linear;
}

@keyframes d8 {
  0%  {box-shadow:0 0 0 0   , 80px 0,-80px 0,0 80px,0 -80px}
  10% {box-shadow:0 0 0 0   , 24px 0,-80px 0,0 80px,0 -80px}
  20% {box-shadow:0 0 0 8px , 0px  0,-80px 0,0 80px,0 -80px}
  30% {box-shadow:0 0 0 8px , 0px  0,-24px 0,0 80px,0 -80px}
  40% {box-shadow:0 0 0 16px , 0px  0,  0px 0,0 80px,0 -80px}
  50% {box-shadow:0 0 0 16px , 0px  0,  0px 0,0 24px,0 -80px}
  60% {box-shadow:0 0 0 24px, 0px  0,  0px 0,0  0px,0 -80px}
  70% {box-shadow:0 0 0 24px, 0px  0,  0px 0,0  0px,0 -24px}
  80% {box-shadow:0 0 0 32px, 0px  0,  0px 0,0  0px,0  0px }
  90%,
  100%{box-shadow:0 0 0 0   , 80px 0,-80px 0,0 80px,0 -80px}
}

.dot-progress-loader {
  width:12px;
  height:12px;
  background: #00A6DB;
  border-radius: 50%;
  box-shadow: 20px 0 #766DF422,-20px 0 #00A6DB;
  animation:d5 1s infinite linear alternate;
}
@keyframes d5 {
    0% {box-shadow: 20px 0 #00A6DB,-20px 0 #766DF422;background: #00A6DB}
    33%{box-shadow: 20px 0 #00A6DB,-20px 0 #766DF422;background: #766DF422}
    66%{box-shadow: 20px 0 #766DF422,-20px 0 #00A6DB;background: #766DF422}
}





.dots {
   width: 70.4px;
   height: 9.6px;
   background: radial-gradient(farthest-side,#00a6db 90%,#0000) 25% 0,
          radial-gradient(farthest-side,#00a6db 90%,#0000) 75% 0;
   background-size: 9.6px 9.6px;
   background-repeat: no-repeat;
   position: relative;
   animation: dots-q4kofwsm 1s linear infinite;
}

.dots:before {
   content: "";
   position: absolute;
   width: 9.6px;
   height: 9.6px;
   border-radius: 50%;
   background: #00a6db;
   inset: 0;
   margin: auto;
   animation: dots-svj0l9sm 1s cubic-bezier(0.5,300,0.5,-300) infinite;
}

@keyframes dots-q4kofwsm {
   0%, 24% {
      background-position: 25% 0,75% 0;
   }

   40% {
      background-position: 25% 0,85% 0;
   }

   50%, 72% {
      background-position: 25% 0,75% 0;
   }

   90% {
      background-position: 15% 0,75% 0;
   }

   100% {
      background-position: 25% 0,75% 0;
   }
}

@keyframes dots-svj0l9sm {
   100% {
      transform: translate(0.08px);
   }
}

